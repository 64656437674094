<template>
	<div class="del-process-wrap">
		<h1 class="title">删除已启动流程（非专业人员请勿操作，结果不可逆）</h1>
		<Spin v-if="loading" fix></Spin>
		<Form
			:model="formData"
			ref="form"
			label-position="top"
			:rules="ruleValidate"
		>
			<Row :gutter="32">
				<Col span="24">
					<FormItem label="项目名称:" prop="projectId">
						<Select
							v-model="formData.projectId"
							placeholder="请选择项目"
							filterable
							@on-change="handleProjectChange"
						>
							<Option
								v-for="item in list"
								:key="item.projectId"
								:value="item.projectId"
								>{{ item.projectName }}</Option
							>
						</Select>
					</FormItem>
				</Col>
			</Row>
			<Row :gutter="32">
				<Col span="24">
					<FormItem label="删除流程:" prop="procInstId">
						<Select v-model="formData.procInstId" placeholder="请选择流程">
							<Option
								v-for="item in processList"
								:key="item.id"
								:value="item.id"
								>{{ item.name }}</Option
							>
						</Select>
					</FormItem>
				</Col>
			</Row>
			<Row :gutter="32">
				<Col span="24">
					<FormItem label="删除原因:" prop="reason">
						<Input
							type="textarea"
							v-model="formData.reason"
							placeholder="请输入删除原因"
						/>
					</FormItem>
				</Col>
			</Row>
			<Row :gutter="32">
				<Col :span="24">
					<Button type="primary" @click="handleSubmit" :loading="loading"
						>提交</Button
					>
				</Col>
			</Row>
		</Form>
	</div>
</template>

<script>
import { mapState } from "vuex"
import api from "@/api/project/process"

const { apiAllProcess, apiDelProcess } = api

export default {
	name: "DelProcess",
	data() {
		return {
			formData: {},
			processList: [],
			loading: false,
			ruleValidate: {
				projectId: [
					{
						required: true,
						message: "项目名称不能为空",
						trigger: "change"
					}
				],
				procInstId: [
					{
						required: true,
						message: "流程不能为空",
						trigger: "change"
					}
				],
				reason: [
					{
						required: true,
						message: "删除原因不能为空",
						trigger: "blur"
					}
				]
			}
		}
	},
	computed: {
		...mapState("project", ["list"])
	},
	mounted() {
		if (!this.list.length) {
			this.$store.dispatch("project/getProjectList")
		}
	},
	methods: {
		async getAllprocess(projectId) {
			this.loading = true
			const res = await apiAllProcess({ projectId })
			if (res) {
				const processList = []
				if (res.data.length) {
					res.data.forEach(item => {
						processList.push(
							...item.processes.filter(process => !process.endTime)
						)
					})
				}
				this.processList = processList
			}
			this.loading = false
		},
		handleProjectChange(projectId) {
			this.getAllprocess(projectId)
			this.$set(this.formData, "procInstId", "")
		},
		handleSubmit() {
			this.$refs.form.validate(valid => {
				if (valid) {
					;(async () => {
						this.loading = true
						const res = await apiDelProcess({
							...this.formData
						})
						if (res) {
							this.$Message.success("操作成功!")
							this.formData = {}
						}
						this.loading = false
					})()
				}
			})
		}
	}
}
</script>

<style lang="less">
.del-process-wrap {
	padding: 20px;
	position: relative;
	.title {
		color: red;
		font-size: 30px;
		line-height: 60px;
		height: 60px;
	}
}
</style>
